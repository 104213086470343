import React from "react";
import RedistrictingTimelineAlertMap from "../components/maps/RedistrictingTimelineAlertMap";
import Layout from "../components/layout";
import SEO from "../components/seo";

import "../styles/styles.css";

const TimelineAlertPage = (context) => {
    return (
        <Layout>
            <SEO
                imagePath={`og-images/redistricting-timeline-map.png`}
                imageHeight={630}
                imageWidth={774}
                title="2021 Redistricting Timeline Alert Map" />
            <RedistrictingTimelineAlertMap />
        </Layout>
    );
};

export default TimelineAlertPage;